body {
  margin: 0;
  //font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  overflow: hidden;
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6,
.card-title {
  font-family: 'Azo Sans';
}

p,
span,
div {
  font-family: 'DINOT-Light';
}

.MuiTypography-body1,
.MuiTypography-h6 {
  font-family: 'Azo Sans' !important;
}

.MuiTableCell-root {
  font-family: 'DINOT-Light' !important;
}

.navigation .nav-item:not(.badge),
.MuiTableCell-head {
  color: black !important;
}

.navigation .nav-item.active,
.navigation .nav-item.active:hover {
  color: white !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  color: rgba(255, 0, 221, 0.863);
  font-size: 16px;
  // color: rgba(255, 162, 0, 0.842);
}

.MuiBadge-colorSecondary-90 {
  color: white !important;
}

.px-6 {
  padding: 20px;
}

.MuiDataGrid-root {
  border: unset !important;
}

.MuiTableCell-root .MuiSvgIcon-root {
  color: #540f00;
}

.MuiTableCell-root span:hover,
.MuiTableCell-root .MuiSvgIcon-root:hover {
  color: rgb(107, 33, 23);
}

.MuiTableCell-root span.MuiButton-label:hover {
  color: white !important;
}

.MuiTableCell-root .MuiButton-root {
  padding: 2px 16px;
}

.advanced-search-container .MuiGrid-item {
  padding-top: 0px !important;
  padding-bottom: 10px !important;
}

.project-groups-expansionpanel-container .MuiTableCell-head {
  padding: 0px !important;
}

.project-groups-expansionpanel-container .MuiTableCell-root {
  padding: 1px !important;
}

.advanced-search-panelist-results-container .MuiTableCell-head {
  padding: 0px !important;
}

.advanced-search-panelist-results-container .MuiTableCell-root {
  padding: 1px !important;
}